import React, { useState, useEffect } from "react";
import { useSpring, animated, to as interpolate } from "react-spring";
import { useDrag } from "react-use-gesture";
import { Shuffle, ChevronLeft, ChevronRight, Anchor } from "lucide-react";

const categories = {
  randomStrangers: {
    name: {
      en: "Icebreakers",
      sk: "Icebreakers",
    },
    questions: {
      en: [
        "What's the best thing that happened to you today?",
        "If you could instantly become an expert in one thing, what would it be?",
        "What's your favorite way to spend a lazy Sunday?",
        "If you could have dinner with any fictional character, who would it be?",
        "If you could live in any era of history, which would you choose?",
        "What's the most unusual item in your pocket or bag right now?",
        "If you could instantly learn a new skill, what would it be?",
        "What's your go-to comfort food?",
        "If you could trade lives with anyone for a day, who would it be?",
        "What's the most beautiful place you've ever been to?",
        "If you could bring back any extinct animal, which one would you choose?",
        "What's the best piece of advice you've ever received from a stranger?",
        "If you could have a endless supply of one food item, what would it be?",
        "What's the most interesting fact you know?",
        "If you could be any fictional character for a day, who would you be?",
        "What's your favorite childhood memory?",
        "If you could instantly master any musical instrument, which would you choose?",
        "What's the most spontaneous thing you've ever done?",
        "If you could have a conversation with your past self, what would you say?",
        "What's the strangest dream you've ever had?",
        "If you could change one thing about the world, what would it be?",
        "What's the best book you've read in the last year?",
        "If you could have any animal as a pet, regardless of practicality, what would it be?",
        "What's the most valuable life lesson you've learned so far?",
      ],
      sk: [
        "Čo je najlepšia vec, ktorá sa ti dnes stala?",
        "Ak by si sa mohol/a okamžite stať expertom v jednej veci, čo by to bolo?",
        "Aký je tvoj obľúbený spôsob trávenia lenivej nedele?",
        "Ak by si mohol/a večerať s akoukoľvek fiktívnou postavou, kto by to bol?",
        "Ak by si mohol/a žiť v akomkoľvek období dejín, ktoré by si si vybral/a?",
        "Aká je najneobvyklejšia vec, ktorú máš práve teraz vo vrecku alebo taške?",
        "Ak by si sa mohol/a okamžite naučiť novú zručnosť, aká by to bola?",
        "Aké je tvoje obľúbené jedlo na upokojenie?",
        "Ak by si si mohol/a vymeniť život s kýmkoľvek na jeden deň, kto by to bol?",
        "Aké je najkrajšie miesto, kde si kedy bol/a?",
        "Ak by si mohol/a priviesť späť akékoľvek vyhynuté zviera, ktoré by si si vybral/a?",
        "Aká je najlepšia rada, ktorú si kedy dostal/a od cudzieho človeka?",
        "Ak by si mohol/a mať nekonečnú zásobu jednej potraviny, čo by to bolo?",
        "Aký je najzaujímavejší fakt, ktorý poznáš?",
        "Ak by si mohol/a byť akoukoľvek fiktívnou postavou na jeden deň, kto by si bol/a?",
        "Aká je tvoja obľúbená spomienka z detstva?",
        "Ak by si mohol/a okamžite ovládnuť akýkoľvek hudobný nástroj, ktorý by si si vybral/a?",
        "Aká je najspontánnejšia vec, ktorú si kedy urobil/a?",
        "Ak by si mohol/a viesť rozhovor so svojím minulým ja, čo by si mu povedal/a?",
        "Aký je najzvláštnejší sen, ktorý sa ti kedy sníval?",
        "Ak by si mohol/a zmeniť jednu vec na svete, čo by to bolo?",
        "Aká je najlepšia kniha, ktorú si prečítal/a za posledný rok?",
        "Ak by si mohol/a mať akékoľvek zviera ako domáceho miláčika, bez ohľadu na praktickosť, aké by to bolo?",
        "Aká je najcennejšia životná lekcia, ktorú si sa doteraz naučil/a?",
      ],
    },
  },
  hostelPeople: {
    name: {
      en: "Global Connections",
      sk: "Global Connections",
    },
    questions: {
      en: [
        "What's the most interesting place you've traveled to and why?",
        "If you could have dinner with any historical figure, who would it be and why?",
        "What's your favorite local dish from your home country?",
        "What's the most adventurous thing you've ever done while traveling?",
        "If you could learn any language instantly, which one would you choose?",
        "What's the strangest food you've ever tried?",
        "What's your go-to karaoke song?",
        "If you could have any superpower, what would it be and why?",
        "What's the best piece of travel advice you've ever received?",
        "What's your favorite childhood memory?",
        "If you could live in any fictional world, which one would you choose?",
        "What's the most interesting job you've ever had?",
        "If you could instantly become an expert in one thing, what would it be?",
        "What's the most beautiful natural wonder you've ever seen?",
        "If you could have a conversation with your future self, what would you ask?",
        "What's the most memorable concert or festival you've attended?",
        "If you could bring back any fashion trend, what would it be?",
        "What's the most unusual place you've ever slept?",
        "If you could have dinner with any three people, dead or alive, who would they be?",
        "What's the best book you've read recently?",
        "If you could teleport anywhere in the world right now, where would you go?",
        "What's the most valuable life lesson you've learned while traveling?",
        "If you could be famous for one thing, what would you want it to be?",
        "What's the most spontaneous thing you've ever done?",
        "If you could change one thing about the world, what would it be?",
      ],
      sk: [
        "Aké je najzaujímavejšie miesto, ktoré si navštívil/a a prečo?",
        "Ak by si mohol/a večerať s akoukoľvek historickou postavou, kto by to bol a prečo?",
        "Aké je tvoje obľúbené miestne jedlo z tvojej domovskej krajiny?",
        "Aká je najdobrodružnejšia vec, ktorú si kedy urobil/a počas cestovania?",
        "Ak by si sa mohol/a okamžite naučiť akýkoľvek jazyk, ktorý by si si vybral/a?",
        "Aké najzvláštnejšie jedlo si kedy skúsil/a?",
        "Aká je tvoja obľúbená karaoke pieseň?",
        "Ak by si mohol/a mať akúkoľvek superschopnosť, aká by to bola a prečo?",
        "Aká je najlepšia rada ohľadom cestovania, ktorú si kedy dostal/a?",
        "Aká je tvoja obľúbená spomienka z detstva?",
        "Ak by si mohol/a žiť v akomkoľvek fiktívnom svete, ktorý by si si vybral/a?",
        "Aká je najzaujímavejšia práca, ktorú si kedy mal/a?",
        "Ak by si sa mohol/a okamžite stať expertom v jednej veci, čo by to bolo?",
        "Aký najkrajší prírodný úkaz si kedy videl/a?",
        "Ak by si mohol/a viesť rozhovor so svojím budúcim ja, na čo by si sa opýtal/a?",
        "Aký je najpamätnejší koncert alebo festival, ktorého si sa zúčastnil/a?",
        "Ak by si mohol/a oživiť akýkoľvek módny trend, ktorý by to bol?",
        "Na akom najneobvyklejšom mieste si kedy spal/a?",
        "Ak by si mohol/a večerať s akýmikoľvek tromi ľuďmi, živými či mŕtvymi, kto by to bol?",
        "Akú najlepšiu knihu si v poslednom čase prečítal/a?",
        "Ak by si sa mohol/a teleportovať kamkoľvek na svete práve teraz, kam by si šiel/šla?",
        "Akú najcennejšiu životnú lekciu si sa naučil/a počas cestovania?",
        "Ak by si mohol/a byť slávny/a pre jednu vec, čo by to bolo?",
        "Aká je najspontánnejšia vec, ktorú si kedy urobil/a?",
        "Ak by si mohol/a zmeniť jednu vec na svete, čo by to bolo?",
      ],
    },
  },
  networking: {
    name: {
      en: "Professional Insights",
      sk: "Professional Insights",
    },
    questions: {
      en: [
        "What's the most exciting project you're working on right now?",
        "How did you get started in your current field?",
        "What's the biggest challenge facing your industry today?",
        "If you could change one thing about your industry, what would it be?",
        "What's the best piece of professional advice you've ever received?",
        "Who has been your most influential mentor, and why?",
        "What's a book that has significantly impacted your career?",
        "What's your favorite productivity hack?",
        "How do you stay up-to-date with industry trends?",
        "What's the most valuable skill you've developed in your career?",
        "If you could master one new professional skill instantly, what would it be?",
        "What's the biggest risk you've taken in your career?",
        "How do you balance work and personal life?",
        "What's your approach to building and maintaining professional relationships?",
        "What's a common misconception about your job or industry?",
        "How has technology changed your field in the last five years?",
        "What's your strategy for continuous learning and improvement?",
        "If you could have a one-hour meeting with any business leader, who would it be?",
        "What's the most innovative idea you've implemented in your work?",
        "How do you handle failure or setbacks in your professional life?",
        "What's your vision for your career in the next five years?",
        "What's the most important leadership lesson you've learned?",
        "How do you approach problem-solving in your work?",
        "What's a current trend in your industry that excites you?",
        "If you could start your career over, what would you do differently?",
      ],
      sk: [
        "Na akom najzaujímavejšom projekte momentálne pracuješ?",
        "Ako si sa dostal/a do svojho súčasného odboru?",
        "Aká je najväčšia výzva, ktorej dnes čelí tvoje odvetvie?",
        "Ak by si mohol/a zmeniť jednu vec vo svojom odvetví, čo by to bolo?",
        "Aká je najlepšia profesionálna rada, ktorú si kedy dostal/a?",
        "Kto bol tvojím najvplyvnejším mentorom a prečo?",
        "Aká kniha významne ovplyvnila tvoju kariéru?",
        "Aký je tvoj obľúbený trik na zvýšenie produktivity?",
        "Ako sa udržiavaš v obraze o trendoch v odvetví?",
        "Aká je najcennejšia zručnosť, ktorú si rozvinul/a vo svojej kariére?",
        "Ak by si mohol/a okamžite ovládnuť jednu novú profesionálnu zručnosť, aká by to bola?",
        "Aké najväčšie riziko si podstúpil/a vo svojej kariére?",
        "Ako vyvažuješ pracovný a osobný život?",
        "Aký je tvoj prístup k budovaniu a udržiavaniu profesionálnych vzťahov?",
        "Aký je bežný mylný názor o tvojej práci alebo odvetví?",
        "Ako zmenila technológia tvoje odvetvie za posledných päť rokov?",
        "Aká je tvoja stratégia pre neustále vzdelávanie a zlepšovanie sa?",
        "Ak by si mohol/a mať hodinové stretnutie s akýmkoľvek obchodným lídrom, kto by to bol?",
        "Aký je najinovatívnejší nápad, ktorý si implementoval/a vo svojej práci?",
        "Ako zvládaš zlyhania alebo neúspechy vo svojom profesionálnom živote?",
        "Aká je tvoja vízia pre tvoju kariéru v najbližších piatich rokoch?",
        "Akú najdôležitejšiu lekciu o vedení ľudí si sa naučil/a?",
        "Aký je tvoj prístup k riešeniu problémov vo svojej práci?",
        "Aký súčasný trend v tvojom odvetví ťa nadchýna?",
        "Ak by si mohol/a začať svoju kariéru odznova, čo by si urobil/a inak?",
      ],
    },
  },
  deepQuestions: {
    name: {
      en: "Deep Questions",
      sk: "Deep Questions",
    },
    questions: {
      en: [
        "What talent or ability would you like to develop and why?",
        "Which moment in your life would you like to relive and why?",
        "If you could change one thing in your community or country, what would it be?",
        "What is your biggest concern about the future and how do you deal with it?",
        "Which book or movie has influenced you the most recently and why?",
        "What would your ideal day look like 10 years from now?",
        "What is your biggest challenge in interpersonal relationships?",
        "What is your most important life goal and why?",
        "When was the last time you overcame your fear and how did it affect you?",
        "If you could leave one message for future generations, what would it be?",
        "What is your most valuable life lesson and how did you come to it?",
        "Which mistake or failure taught you the most and how?",
        "How would you describe your life in one sentence?",
        "What is the hardest thing for you to forgive yourself or others for?",
        "What is your biggest dream that you haven't had the courage to pursue yet?",
        "How would you define success in your life?",
        "Which moment in life changed you the most and why?",
        "What values are most important to you and how do you express them in everyday life?",
        "When was the last time you did something for the first time and how did it feel?",
        "What is your biggest source of motivation?",
        "What would you like people to say about you at your funeral?",
        "What would your life look like if you weren't afraid of failure?",
        "Which quality would you most like to develop in yourself and why?",
        "What is your greatest untapped potential?",
        "When was the last time you changed your mind about something important and why?",
        "How do you try to contribute to a better world?",
        "What helps you stay optimistic in difficult times?",
        "What is your biggest life compromise and do you regret it?",
        "Which three words would best describe you and why?",
        "What would your life look like if you had chosen a different career path?",
        "What is your greatest source of inner peace?",
        "What is your biggest unresolved conflict and why aren't you resolving it?",
        "Which life experience surprised you the most?",
        "How would you like to be remembered by your loved ones?",
        "What advice would you give to your younger self if you had the chance?",
        "What's the hardest truth you've had to accept about yourself?",
        "How has your definition of happiness changed over time?",
        "What's a belief you hold with which many people disagree?",
        "In what ways are you different from the person you were five years ago?",
        "What's the most significant sacrifice you've made for someone else?",
        "How do you think your childhood has shaped your current personality?",
        "What's a question you're afraid to know the answer to?",
        "How do you want to be remembered after you're gone?",
        "What's the most important lesson life has taught you so far?",
        "What's something you believe that you can't prove?",
        "How do you define 'purpose' in life, and have you found yours?",
        "What's the biggest risk you've ever taken? Was it worth it?",
        "How do you balance living in the present with planning for the future?",
        "What's a personal rule you have that you never break?",
        "How has a failure or setback in your life turned into a blessing?",
        "What's something you know now that you wish you knew five years ago?",
        "How do you measure the value of a life well-lived?",
        "What's the hardest decision you've ever had to make?",
        "How do you stay true to yourself in a world that's constantly trying to change you?",
        "What's a truth about yourself that you're afraid to admit?",
        "How has your perspective on love and relationships evolved over time?",
        "What's the most significant way in which you've grown as a person in the last year?",
        "How do you find meaning in life during difficult times?",
        "What's a personal belief you held strongly that you later changed your mind about?",
        "How do you balance your personal needs with the needs of others in your life?",
      ],
      sk: [
        "Aký talent alebo schopnosť by si chcel/a rozvinúť a prečo?",
        "Ktorý moment tvojho života by si chcel/a znovu prežiť a prečo?",
        "Ak by si mohol/a zmeniť jednu vec vo svojej komunite alebo krajine, čo by to bolo?",
        "Aká je tvoja najväčšia obava o budúcnosť a ako s ňou pracuješ?",
        "Ktorá kniha alebo film ťa v poslednom čase najviac ovplyvnil/a a prečo?",
        "Ako by vyzeral tvoj ideálny deň o 10 rokov?",
        "Čo je pre teba najväčšou výzvou v medziľudských vzťahoch?",
        "Aký je tvoj najdôležitejší životný cieľ a prečo?",
        "Kedy si naposledy prekonal/a svoj strach a aký to malo na teba vplyv?",
        "Ak by si mohol/a zanechať jeden odkaz budúcim generáciám, čo by to bolo?",
        "Aká je tvoja najcennejšia životná lekcia a ako si k nej prišiel/prišla?",
        "Ktorá chyba alebo zlyhanie ťa najviac naučilo a ako?",
        "Ako by si opísal/a svoj život jednou vetou?",
        "Čo je pre teba najťažšie odpustiť sebe alebo iným?",
        "Aký je tvoj najväčší sen, ktorý si zatiaľ nemal/a odvahu realizovať?",
        "Ako by si definoval/a úspech vo svojom živote?",
        "Ktorý moment v živote ťa najviac zmenil a prečo?",
        "Aké hodnoty sú pre teba najdôležitejšie a ako ich prejavuješ v každodennom živote?",
        "Kedy si naposledy urobil/a niečo prvýkrát a aký to bol pocit?",
        "Aký je tvoj najväčší zdroj motivácie?",
        "Čo by si chcel/a, aby o tebe ľudia povedali na tvojom pohrebe?",
        "Ako by vyzeral tvoj život, keby si sa nebál/a zlyhania?",
        "Ktorú vlastnosť by si chcel/a v sebe najviac rozvíjať a prečo?",
        "Aký je tvoj najväčší nevyužitý potenciál?",
        "Kedy si naposledy zmenil/a svoj názor na niečo dôležité a prečo?",
        "Ako sa snažíš prispievať k lepšiemu svetu?",
        "Čo ti pomáha zostať optimistický/á v ťažkých časoch?",
        "Aký je tvoj najväčší životný kompromis a ľutuješ ho?",
        "Ktoré tri slová by ťa najlepšie opísali a prečo?",
        "Ako by vyzeral tvoj život, keby si sa rozhodol/a pre inú kariérnu cestu?",
        "Čo je pre teba najväčším zdrojom vnútorného pokoja?",
        "Aký je tvoj najväčší nevyriešený konflikt a prečo ho neriešiš?",
        "Ktorá životná skúsenosť ťa najviac prekvapila?",
        "Ako by si chcel/a, aby ťa pamätali tvoji najbližší?",
        "Čo by si poradil/a svojmu mladšiemu ja, keby si mal/a tú možnosť?",
        "Aká je najťažšia pravda, ktorú si musel/a o sebe prijať?",
        "Ako sa zmenila tvoja definícia šťastia v priebehu času?",
        "Aký názor zastávaš, s ktorým mnohí ľudia nesúhlasia?",
        "V čom si iný/á oproti osobe, ktorou si bol/a pred piatimi rokmi?",
        "Akú najvýznamnejšiu obetu si urobil/a pre niekoho iného?",
        "Ako si myslíš, že tvoje detstvo ovplyvnilo tvoju súčasnú osobnosť?",
        "Na akú otázku sa bojíš poznať odpoveď?",
        "Ako chceš byť zapamätaný/á po svojom odchode?",
        "Akú najdôležitejšiu lekciu ťa život doteraz naučil?",
        "Čomu veríš, aj keď to nevieš dokázať?",
        "Ako definuješ 'zmysel' života a našiel/našla si už ten svoj?",
        "Aké najväčšie riziko si kedy podstúpil/a? Stálo to za to?",
        "Ako vyvažuješ život v prítomnosti s plánovaním budúcnosti?",
        "Aké osobné pravidlo máš, ktoré nikdy neporušíš?",
        "Ako sa nejaké zlyhanie alebo neúspech v tvojom živote premenilo na požehnanie?",
        "Čo vieš teraz, čo by si si prial/a vedieť pred piatimi rokmi?",
        "Ako meriaš hodnotu dobre prežitého života?",
        "Aké najťažšie rozhodnutie si kedy musel/a urobiť?",
        "Ako zostávaš verný/á sebe samému/samej vo svete, ktorý sa ťa neustále snaží zmeniť?",
        "Akú pravdu o sebe sa bojíš priznať?",
        "Ako sa vyvíjal tvoj pohľad na lásku a vzťahy v priebehu času?",
        "Akým najvýznamnejším spôsobom si za posledný rok vyrástol/vyrástla ako osoba?",
        "Ako nachádzaš zmysel života v ťažkých časoch?",
        "Aký osobný názor si silno zastával/a, ale neskôr si zmenil/a názor?",
        "Ako vyvažuješ svoje osobné potreby s potrebami ostatných vo svojom živote?",
      ],
    },
  },
};

const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

const to = (i) => ({
  x: 0,
  y: i * -4,
  scale: 1 - i * 0.05,
  rot: -5 + Math.random() * 10,
  delay: i * 100,
});
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });

const trans = (r, s) =>
  `perspective(1500px) rotateX(10deg) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;

const DeepDiveApp = () => {
  const [language, setLanguage] = useState("en");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState(0);
  const [props, set] = useSpring(() => ({ to: { ...to(0) }, from: from(0) }));

  useEffect(() => {
    const isSlovak = window.location.pathname.includes("/sk");
    setLanguage(isSlovak ? "sk" : "en");
  }, []);

  const selectCategory = (category) => {
    setSelectedCategory(category);
    setQuestions(shuffleArray([...categories[category].questions[language]]));
    setIndex(0);
    set((i) => to(i));
  };

  const moveCard = (direction) => {
    setIndex(
      (prevIndex) =>
        (prevIndex + direction + questions.length) % questions.length
    );
    set({ x: direction * 100, rot: direction * 5, scale: 0.8 });
    setTimeout(() => set({ x: 0, rot: 0, scale: 1 }), 300);
  };

  const bind = useDrag(
    ({ down, movement: [mx], direction: [xDir], velocity, event }) => {
      event.preventDefault();
      const trigger = velocity > 0.2;
      const dir = xDir < 0 ? 1 : -1;

      if (!down && trigger) {
        moveCard(dir);
      } else {
        set({
          x: down ? mx : 0,
          rot: mx / 100,
          scale: down ? 1.1 : 1,
          config: { friction: 50, tension: down ? 800 : 500 },
        });
      }
    },
    {
      filterTaps: true,
      rubberband: true,
      axis: "x",
    }
  );

  useEffect(() => {
    set((i) => to(i));
  }, [index, set]);

  const shuffleAndReset = () => {
    setQuestions(shuffleArray([...questions]));
    setIndex(0);
    set((i) => to(i));
  };

  const texts = {
    title: "Deep Dive",
    subtitle: language === "en" ? "Choose a category" : "Vyberte kategóriu",
    shuffle: language === "en" ? "Shuffle and Reset" : "Zamiešať a Obnoviť",
    swipeHint:
      language === "en"
        ? "Swipe or use arrows to navigate questions"
        : "Potiahni alebo použi šípky na navigáciu otázok",
    changeCategory: language === "en" ? "Change Category" : "Zmeniť Kategóriu",
  };

  const Logo = () => (
    <div className="flex items-center justify-center mb-8">
      <Anchor size={40} className="text-black mr-2" />

      <h1 className="text-4xl sm:text-5xl font-bold text-gray-800 font-sans">
        Deep Dive
      </h1>
    </div>
  );

  if (!selectedCategory) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4 ">
        <Logo />
        <p className="text-xl text-gray-600 mb-8">{texts.subtitle}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-lg">
          {Object.keys(categories).map((category) => (
            <button
              key={category}
              className="px-6 py-4 bg-white text-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 text-lg font-semibold transform hover:scale-105 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
              onClick={() => selectCategory(category)}
            >
              {categories[category].name[language]}
            </button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-gray-50 py-4 px-4 sm:py-8 sm:px-6">
      <Logo />
      <div className="flex-grow flex flex-col justify-center w-full max-w-xs sm:max-w-sm md:max-w-md">
        <div className="relative aspect-[3/4] mb-6 sm:mb-8 touch-none">
          {[1, 0].map((i) => (
            <animated.div
              key={i}
              style={{
                transform: interpolate([props.rot, props.scale], trans),
                ...props,
                zIndex: questions.length - i,
              }}
              {...bind()}
              className="absolute w-full h-full will-change-transform cursor-grab active:cursor-grabbing"
            >
              <animated.div
                style={{
                  transform: interpolate([props.rot, props.scale], trans),
                }}
                className={`rounded-2xl shadow-lg w-full h-full flex flex-col justify-between p-4 sm:p-6 bg-white text-gray-800`}
              >
                <div className="flex justify-between items-center text-sm font-semibold">
                  <span>{categories[selectedCategory].name[language]}</span>
                  <span className="px-2 py-1 bg-gray-200 text-gray-700 rounded-md text-xs">
                    Deep Dive
                  </span>
                </div>
                <p className="text-center text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold">
                  {questions[(index + i) % questions.length]}
                </p>
                <div className="text-right text-md font-bold text-gray-400">
                  {language === "en" ? "?" : "?"}
                </div>
              </animated.div>
            </animated.div>
          ))}
        </div>

        <div className="flex items-center justify-center space-x-4 mb-4 pt-6 sm:mb-6">
          <button
            className="p-2 bg-gray-200 text-gray-800 rounded-full shadow-md hover:bg-gray-300 transition-all duration-300 transform hover:scale-105 active:scale-95"
            onClick={() => moveCard(-1)}
          >
            <ChevronLeft size={24} />
          </button>
          <button
            className="px-4 py-2 sm:px-6 sm:py-3 bg-gray-800 text-white rounded-full flex items-center justify-center shadow-md hover:bg-gray-700 transition-all duration-300 text-sm sm:text-base font-semibold transform hover:scale-105 active:scale-95"
            onClick={shuffleAndReset}
          >
            <Shuffle className="mr-2" size={16} />
            {texts.shuffle}
          </button>
          <button
            className="p-2 bg-gray-200 text-gray-800 rounded-full shadow-md hover:bg-gray-300 transition-all duration-300 transform hover:scale-105 active:scale-95"
            onClick={() => moveCard(1)}
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>

<div className="mt-4 text-center">
        <p className="text-gray-600 text-xs sm:text-sm mb-2">
          {texts.swipeHint}
        </p>
        <button
          className="text-gray-600 underline text-sm hover:text-gray-800 transition-colors duration-300"
          onClick={() => setSelectedCategory(null)}
        >
          {texts.changeCategory}
        </button>
              <p className="text-gray-600 text-xs sm:text-sm mb-2 pt-6">
  Created by <a href="https://www.linkedin.com/in/svojtko/" className="text-purple-400">svojtko</a>
</p>

      </div>

    </div>
  );
};

export default DeepDiveApp;
